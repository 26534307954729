
.nav-logo {
  width: 130px;
  height: 36px;
  padding-left: 24px;
  background: url('@/assets/logo.png');
  background-size: contain;
  background-repeat: no-repeat;
}

