.language-box {
  :global {
    .ant-segmented-group {
      border-radius: 6px;
      overflow: hidden;
      border: 2px solid var(--primary);
      .ant-segmented-item  {
        // border-radius: 50px;
        overflow: hidden;
        box-shadow: 0;
        &:not(.ant-segmented-item-selected):hover {
          &:after {
            background-color: transparent;
            // border-radius: 50px;
        overflow: hidden;
          }
        }
      }
    }
  }

}

.radios {
  border: 2px solid var(--primary);
  border-radius: 50px;
  overflow: hidden;
  :global {
    .ant-radio-button-wrapper {
      border-width: 0;
      color: var(--primary);
      border-radius: 50px;
    }
    .ant-radio-button-wrapper {
      &::before {
        display: none;
      }
    }
  }
}
