

@navText: #010b17;

.nav-container {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  color: @navText;
  padding: 24px 0;
  z-index: 10;
  .nav-header {
    margin: auto;
    display: flex;


    .nav-anchor {
      flex: 1;
      :global {
        .ant-anchor-wrapper {
          &:before {
            display: none;
          }
        }
        .ant-anchor {
          .ant-anchor-link {
            margin-right: 48px;
          }
          .ant-anchor-link-title {
            color: @navText;
          }
        }
      }
    }

    .nav-list {
      color: #fff!important;
      flex: 1;
      display: flex;
      list-style: none;
    }

    .nav-actions {
      // width: 90px;
    }
  }
}

@media screen and (max-width: 800px) {
  .nav-header {
    justify-content: space-between;
    padding: 0 24px;
  }
  .nav-anchor {
    display: none;
  }
}
