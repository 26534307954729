.footer {
  padding: 48px 0 120px;
  color: var(--text-primary);
  text-align: center;
  .footer-name {
    font-size: 20px;
  }
  .copyright {
    font-size: 16px;
    color: #a9a9a9;
  }
}

@media screen and (max-width: 800px) {
  .footer {
    padding: 16px 16px 24px;
    text-align: left;
  }
}
